import * as React from "react";
import logouticon from './images/sair_icon.PNG';


const Logouter = ({lgaction}) => {
	return (
		<div className="app-logout" onClick={lgaction}>
			<img src={logouticon} className="app-logout-icon" alt="Logout" />
			<div className="app-logout-text">Sair</div>
		</div>
	);
};


export default Logouter;