import * as React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import LocationView from './LocationView';
import FormView from './FormView';
import $ from "jquery";


const WS_URL = 'https://api.simulador.lojas.santander.bigdata.com.br';
const BEARER = window.tk_auth;


const App = () => {
	const [loading, setLoading] = useState(false);
	const [zoom, setZoom] = useState(5); // initial zoom
	const [center, setCenter] = useState({lat: -12.339611239724256, lng: -53.026985835624195}); // initial center
	const [markers, setMarkers] = useState([{"lat": "", "lng": ""}]); // initially, no markers on map
	const [simulations, setSimulations] = useState([]);
	const [fdSC, setFdSC] = useState('');
	const [fdCEP, setFdCEP] = useState('');
	const [fdRua, setFdRua] = useState('');
	const [fdNum, setFdNum] = useState('');
	const [fdBai, setFdBai] = useState('');
	const [fdCid, setFdCid] = useState('');
	const [fdUF, setFdUF] = useState('');
	const [q1, setQ1] = useState('');
	const [q2, setQ2] = useState('');
	const [q3, setQ3] = useState([]);
	const [q4, setQ4] = useState('Sim');
	const [q5, setQ5] = useState([]);
	const [q6, setQ6] = useState([]);
	const [q7, setQ7] = useState('');
	const [q8, setQ8] = useState('');
	const [q9, setQ9] = useState('Área Residencial');
	const [q10, setQ10] = useState('Sim');
	const [q11, setQ11] = useState('Sim');
	const [q12, setQ12] = useState('');
	const [q13, setQ13] = useState('');
	const [q14, setQ14] = useState('');
	const [q15, setQ15] = useState('Sim');
	const [q16, setQ16] = useState([]);
	const [q17, setQ17] = useState([]);
	const [q18, setQ18] = useState([]);
	const [q19, setQ19] = useState('A agência fica em um shopping');
	const [q20, setQ20] = useState('Existe um estacionamento privado na agência');
	const [q21, setQ21] = useState('Sim, existe um restaurante/lanchonete dentro da agência');
	const [q22, setQ22] = useState('A agência fica em uma instituição de ensino');
	const [q23, setQ23] = useState('Sim, em menos de 1 quadra');
	const [q24, setQ24] = useState('Faculdade');
	const [q25, setQ25] = useState('Sim');
	const [q26, setQ26] = useState([]);
	const [q27, setQ27] = useState('Sim, a menos de 1 quilômetro');
	const [fluxo, setFluxo] = useState('0');

	const handleLatLng = (marker) => {
		setMarkers([marker]);
		if(marker.lat && marker.lng){
			setLoading(true);
			$.post({
				"url": WS_URL+'/get_cod_setor',
				"headers": {
					"Authorization": BEARER,
					"Content-Type": "application/json"
				},
				"data": JSON.stringify({"lat": marker.lat, "lon": marker.lng}), // a API bigdata espera a chave "lon", não "lng"
				"success": (rsp) => {
					setFdSC(''+rsp.cod_setor);
					setLoading(false);
				},
				"error": () => {
					alert('Algo deu errado e o setor censitário não pode ser obtido');
					setLoading(false);
				}
			});
		}
	};

	const onIdleMap = (m) => {
		setZoom(m.getZoom());
		setCenter(m.getCenter().toJSON());
	};

	const onChangeLatLng = (k, v) => {
		let [marker] = markers;
		marker[k] = v;
		setMarkers([marker]);
	};

	const onChangeAp = (v) => {
		setFdSC(v);
	};
	
	const onChangeAddress = (fd, v) => {
		if(fd === 'fdCEP'){
			setFdCEP(v.replace(/\D/g, ''));
		}
		else if(fd === 'fdRua'){
			setFdRua(v);
		}
		else if(fd === 'fdNum'){
			setFdNum(v);
		}
		else if(fd === 'fdBai'){
			setFdBai(v);
		}
		else if(fd === 'fdCid'){
			setFdCid(v);
		}
		else if(fd === 'fdUF'){
			setFdUF(v);
		}
	};

	const onChangeQuestion = (q, v) => {
		const qmap = {
			"q1": () => setQ1(v),
			"q2": () => setQ2(v),
			"q3": () => setQ3(v),
			"q4": () => setQ4(v),
			"q5": () => setQ5(v),
			"q6": () => setQ6(v),
			"q7": () => setQ7(v),
			"q8": () => setQ8(v),
			"q9": () => setQ9(v),
			"q10": () => setQ10(v),
			"q11": () => setQ11(v),
			"q12": () => setQ12(v),
			"q13": () => setQ13(v),
			"q14": () => setQ14(v),
			"q15": () => setQ15(v),
			"q16": () => setQ16(v),
			"q17": () => setQ17(v),
			"q18": () => setQ18(v),
			"q19": () => setQ19(v),
			"q20": () => setQ20(v),
			"q21": () => setQ21(v),
			"q22": () => setQ22(v),
			"q23": () => setQ23(v),
			"q24": () => setQ24(v),
			"q25": () => setQ25(v),
			"q26": () => setQ26(v),
			"q27": () => setQ27(v)
		};
		qmap[q]();
	};

	const onResetQuestions = () => {
		setQ1('');
		setQ2('');
		setQ3([]);
		setQ4('Sim');
		setQ5([]);
		setQ6([]);
		setQ7('');
		setQ8('');
		setQ9('Área Residencial');
		setQ10('Sim');
		setQ11('Sim');
		setQ12('');
		setQ13('');
		setQ14('');
		setQ15('Sim');
		setQ16([]);
		setQ17([]);
		setQ18([]);
		setQ19('A agência fica em um shopping');
		setQ20('Existe um estacionamento privado na agência');
		setQ21('Sim, existe um restaurante/lanchonete dentro da agência');
		setQ22('A agência fica em uma instituição de ensino');
		setQ23('Sim, em menos de 1 quadra');
		setQ24('Faculdade');
		setQ25('Sim');
		setQ26([]);
		setQ27('Sim, a menos de 1 quilômetro');
		setFluxo('0');
	};

	const onClickCalcPredict = () => {
		var dados = {
			"cod_setor": fdSC,
			"question_01": q1,
			"question_02": q2,
			"question_03": q3.join(';'),
			"question_04": q4,
			"question_05": q5.join(';'),
			"question_06": q6.join(';'),
			"question_07": q7,
			"question_08": q8,
			"question_09": q9,
			"question_10": q10,
			"question_11": q11,
			"question_12": q12,
			"question_13": q13,
			"question_14": q14,
			"question_15": q15,
			"question_16": q16.join(';'),
			"question_17": q17.join(';'),
			"question_18": q18.join(';'),
			"question_19": q19,
			"question_20": q20,
			"question_21": q21,
			"question_22": q22,
			"question_23": q23,
			"question_24": q24,
			"question_25": q25,
			"question_26": q26.join(';'),
			"question_27": q27
		};
		setLoading(true);
		$.post({
			"url": WS_URL+'/predict',
			"headers": {
				"Authorization": BEARER,
				"Content-Type": "application/json"
			},
			"data": JSON.stringify(dados),
			"success": (rsp) => {
				setFluxo(''+rsp.fluxo);
				setLoading(false);
			},
			"error": () => {
				alert('Algo de errado aconteceu ao calcular a previsão');
				setLoading(false);
			}
		});
	};

	const onSelectSim = (e, val) => {
		var dados = {"simulation_name": val}
		setLoading(true);
		$.post({
			"url": WS_URL+'/get_simulation',
			"headers": {
				"Authorization": BEARER,
				"Content-Type": "application/json"
			},
			"data": JSON.stringify(dados),
			"success": (rsp) => {
				for(var k in rsp){
					rsp[k] = (rsp[k]) ? rsp[k] : '';
				}
				var q3 = rsp.question_03.split(';');
				var q5 = rsp.question_05.split(';');
				var q6 = rsp.question_06.split(';');
				var q16 = rsp.question_16.split(';');
				var q17 = rsp.question_17.split(';');
				var q18 = rsp.question_18.split(';');
				var q26 = rsp.question_26.split(';');
				setMarkers([{"lat": rsp.lat, "lng": rsp.lon}]);
				setFdSC(''+rsp.cod_setor);
				setFdCEP(rsp.postal_code);
				setFdRua(rsp.street_address);
				setFdNum(''+rsp.address_number);
				setFdBai(rsp.district);
				setFdCid(rsp.city);
				setFdUF(rsp.state);
				setQ1(rsp.question_01);
				setQ2(rsp.question_02);
				setQ3(q3);
				setQ4(rsp.question_04);
				setQ5(q5);
				setQ6(q6);
				setQ7(rsp.question_07);
				setQ8(rsp.question_08);
				setQ9(rsp.question_09);
				setQ10(rsp.question_10);
				setQ11(rsp.question_11);
				setQ12(rsp.question_12);
				setQ13(rsp.question_13);
				setQ14(rsp.question_14);
				setQ15(rsp.question_15);
				setQ16(q16);
				setQ17(q17);
				setQ18(q18);
				setQ19(rsp.question_19);
				setQ20(rsp.question_20);
				setQ21(rsp.question_21);
				setQ22(rsp.question_22);
				setQ23(rsp.question_23);
				setQ24(rsp.question_24);
				setQ25(rsp.question_25);
				setQ26(q26);
				setQ27(rsp.question_27);
				setFluxo(''+rsp.fluxo);
				setLoading(false);
			},
			"error": () => {
				alert('Por algum motivo, a simulação não pode ser carregada');
				setLoading(false);
			}
		});
	};

	const onClickSaveSim = (name) => {
		var dados = {
			"simulation_name": name,
			"cod_setor": fdSC,
			"address_number": ''+fdNum,
			"street_address": fdRua,
			"district": fdBai,
			"city": fdCid,
			"state": fdUF,
			"lat": markers[0].lat,
			"lon": markers[0].lng,
			"postal_code": fdCEP,
			"question_01": q1,
			"question_02": q2,
			"question_03": q3.join(';'),
			"question_04": q4,
			"question_05": q5.join(';'),
			"question_06": q6.join(';'),
			"question_07": q7,
			"question_08": q8,
			"question_09": q9,
			"question_10": q10,
			"question_11": q11,
			"question_12": q12,
			"question_13": q13,
			"question_14": q14,
			"question_15": q15,
			"question_16": q16.join(';'),
			"question_17": q17.join(';'),
			"question_18": q18.join(';'),
			"question_19": q19,
			"question_20": q20,
			"question_21": q21,
			"question_22": q22,
			"question_23": q23,
			"question_24": q24,
			"question_25": q25,
			"question_26": q26.join(';'),
			"question_27": q27,
			"fluxo": +fluxo
		};
		setLoading(true);
		$.post({
			"url": WS_URL+'/save_simulation',
			"headers": {
				"Authorization": BEARER,
				"Content-Type": "application/json"
			},
			"data": JSON.stringify(dados),
			"success": (rsp) => {
				if(rsp.status){
					const [...newSimulations] = simulations;
					newSimulations[newSimulations.length] = {"val": name, "label": name};
					setSimulations(newSimulations);
				}
				else{
					alert(rsp.msg);
				}
				setLoading(false);
			},
			"error": () => {
				alert('A simulação não foi salva. Tente novamente mais tarde.');
				setLoading(false);
			}
		});
	};

	const logout = () => {
		document.cookie = "tk_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		window.location.reload();
	};

	useEffect(() => {
		if(!simulations.length){
			$.get({
				"url": WS_URL+'/get_simulation_list',
				"headers": {
					"Authorization": BEARER,
					"Content-Type": "application/json"
				},
				"success": (rsp) => {
					if(rsp.simulations_list.length){
						setSimulations(rsp.simulations_list.map((s) => ({"val": s, "label": s})));
					}
				}
			});
		}
	}, [simulations]);

	return (
		<>
			<Router>
				<Routes>
					<Route path="/form" element={
						<FormView
							globals={{"bearer": BEARER, "wsurl": WS_URL, "setLoading": setLoading, "logout": logout}}
							markers={markers}
							fdSC={fdSC}
							fdCEP={fdCEP}
							fdRua={fdRua}
							fdNum={fdNum}
							fdBai={fdBai}
							fdCid={fdCid}
							fdUF={fdUF}
							q1={q1}
							q2={q2}
							q3={q3}
							q4={q4}
							q5={q5}
							q6={q6}
							q7={q7}
							q8={q8}
							q9={q9}
							q10={q10}
							q11={q11}
							q12={q12}
							q13={q13}
							q14={q14}
							q15={q15}
							q16={q16}
							q17={q17}
							q18={q18}
							q19={q19}
							q20={q20}
							q21={q21}
							q22={q22}
							q23={q23}
							q24={q24}
							q25={q25}
							q26={q26}
							q27={q27}
							fluxo={fluxo}
							onChangeAp={onChangeAp}
							onChangeAddress={onChangeAddress}
							onChangeLatLng={onChangeLatLng}
							onChangeQuestion={onChangeQuestion}
							onResetQuestions={onResetQuestions}
							onClickCalcPredict={onClickCalcPredict}
							onClickSaveSim={onClickSaveSim}
						/>
					} />
					<Route path="*" element={
						<LocationView
							globals={{"bearer": BEARER, "wsurl": WS_URL, "setLoading": setLoading, "logout": logout}}
							zoom={zoom}
							center={center}
							markers={markers}
							simulations={simulations}
							fdSC={fdSC}
							fdCEP={fdCEP}
							fdRua={fdRua}
							fdNum={fdNum}
							fdBai={fdBai}
							fdCid={fdCid}
							fdUF={fdUF}
							handleLatLng={handleLatLng}
							onIdleMap={onIdleMap}
							onChangeAp={onChangeAp}
							onChangeAddress={onChangeAddress}
							onChangeLatLng={onChangeLatLng}
							onChangeQuestion={onChangeQuestion}
							onSelectSim={onSelectSim}
						/>
					} />
				</Routes>
			</Router>
			<div id="gn-loading" className={`fade ${(loading) ? "show" : ""}`}>
				<div className="gn-wrapper">
					<i className="fa fa-fire-alt fa-beat gn-fa-icon"></i>
					Carregando...
				</div>
			</div>
		</>
	);
};


export default App;
