import * as React from "react";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Logouter from './Logouter';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import santanderlogo from './images/Santander_logo.PNG';


const FormView = ({globals, markers, onChangeAp, onChangeAddress, onChangeLatLng, onChangeQuestion, onResetQuestions, onClickCalcPredict, onClickSaveSim, ...rest}) => {
	const [panel, setPanel] = useState('panel-collapsed');
	const refNomeSim = useRef(null);
	
	const section1 = "Características gerais e Funcionamento da agência";
	const section2 = "Serviços disponíveis";
	const section3 = "Estrutura e Localização";

	const disbdCaractGerais = !(rest.fdSC); // section 1
	const disbdServDisp = (disbdCaractGerais || !(rest.q1 && rest.q2 && rest.q3 && rest.q4 && rest.q5)); // section 2
	const disbdEstrLocaliz = (disbdServDisp || !(rest.q6.length && rest.q7 && rest.q8)); // section 3

	return (
		<Container fluid className={`form-view ${panel}`}>
			<div className="form-panel-bg"></div>
			<div className="form-panel">
				<Row className="h-100">
					<Col xs="auto" className="form-panel-sections">
						<div className={`form-panel-sections-inner  ${(disbdCaractGerais) ? 'justify-content-end' : ''}`}>
							<div className={`form-panel-controller ${(disbdCaractGerais) ? 'section-disabled' : ''}`}>
								<div className="form-panel-controller-icon icon1" title={section1}></div>
								<div className="form-panel-controller-text">{section1}</div>
							</div>
							<div className={`form-panel-controller ${(disbdServDisp) ? 'section-disabled' : ''}`}>
								<div className="form-panel-controller-icon icon2" title={section2}></div>
								<div className="form-panel-controller-text">{section2}</div>
							</div>
							<div className={`form-panel-controller ${(disbdEstrLocaliz) ? 'section-disabled' : ''}`}>
								<div className="form-panel-controller-icon icon3" title={section3}></div>
								<div className="form-panel-controller-text">{section3}</div>
							</div>
							<Row className={`my-1 g-2 pt-5 ${(panel === '') ? 'invisible': ''}`}>
								<Col xs={6} className="d-grid">
									<Button
										variant="link-red"
										type="button"
										size="lg"
										onClick={() => onResetQuestions()}
									>
										Apagar
									</Button>
								</Col>
								<Col xs={6} className="d-grid">
									<Button
										variant="red"
										type="button"
										size="lg"
										onClick={() => {
											setPanel('');
											!disbdEstrLocaliz && onClickCalcPredict();
										}}
									>
										Avançar
									</Button>
								</Col>
							</Row>
						</div>
					</Col>
					<Col className="form-panel-results">
						<div className="form-panel-results-inner">
							<h1 className="mb-5">Pontencial de fluxo:</h1>
							<div className="d-flex align-items-center px-4">
								<span className="app-result me-4">{rest.fluxo.padStart(5, '0')}</span><span className="fs-4 fw-500">Clientes/mês</span>
							</div>
							<InputGroup className="mb-3 px-4 pt-4" size="lg">
								<Form.Control
									ref={refNomeSim}
									placeholder="Nome da simulação"
									aria-label="Nome da simulação"
									aria-describedby="Para salvar a simulação"
								/>
								<Button variant="red" id="save-sim" onClick={() => onClickSaveSim(refNomeSim.current.value)}>Salvar</Button>
							</InputGroup>
							<div className="px-4 text-end">
								<span className="text-decoration-underline fw-500 cur-pointer" onClick={() => export_to_csv_file({markers, ...rest})}>
									<i className="fa fa-download me-1"></i>
									Baixar simulação
								</span>
							</div>
							<Row className="mt-5 p-4 g-2 mx-0">
								<Col xs={6} className="d-grid">
									<Button variant="outline-secondary" type="button" size="lg" onClick={() => setPanel('panel-collapsed')}>
										Voltar
									</Button>
								</Col>
								<Col xs={6} className="d-grid">
									<Link to="/" className="btn btn-outline-secondary btn-lg">Tela inicial</Link>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</div>
			<div className="form-lacation">
				<div className="p-3">
					<img src={santanderlogo} className="App-logo" alt="logo" />
					<Logouter lgaction={globals.logout} />
				</div>
				<Row className="py-4 px-3">
					<Col className="px-lg-4">
						<Row className="g-2">
							<Col md={12}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-sc"
										className="one-line-input"
										placeholder="Setor censitário"
										value={rest.fdSC}
										onChange={(e) => onChangeAp(e.target.value)}
									/>
									<label htmlFor="fv-sc">Setor censitário</label>
									<span className={`input-closer ${(rest.fdSC) ? '' : 'd-none'}`} onClick={(e) => onChangeAp('')}>x</span>
								</Form.Floating>
							</Col>
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="number"
										id="fv-lat"
										placeholder="Latitude"
										value={markers[0].lat}
										onChange={(e) => onChangeLatLng('lat', e.target.value)}
									/>
									<label htmlFor="fv-lat">Latitude</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="number"
										id="fv-lng"
										placeholder="Longitude"
										value={markers[0].lng}
										onChange={(e) => onChangeLatLng('lng', e.target.value)}
									/>
									<label htmlFor="fv-lng">Longitude</label>
								</Form.Floating>
							</Col>
						</Row>
					</Col>
					<Col className="px-lg-4">
						<Row className="g-2">
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-cep"
										value={rest.fdCEP}
										placeholder="CEP"
										onChange={(e) => onChangeAddress('fdCEP', e.target.value)}
									/>
									<label htmlFor="fv-cep">CEP</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={6}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-rua"
										value={rest.fdRua}
										placeholder="Rua"
										onChange={(e) => onChangeAddress('fdRua', e.target.value)}
									/>
									<label htmlFor="fv-rua">Rua</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={2}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-num"
										value={rest.fdNum}
										placeholder="Número"
										onChange={(e) => onChangeAddress('fdNum', e.target.value)}
									/>
									<label htmlFor="fv-num">Número</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-bairro"
										value={rest.fdBai}
										placeholder="Bairro"
										onChange={(e) => onChangeAddress('fdBai', e.target.value)}
									/>
									<label htmlFor="fv-bairro">Bairro</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-cid"
										value={rest.fdCid}
										placeholder="Cidade"
										onChange={(e) => onChangeAddress('fdCid', e.target.value)}
									/>
									<label htmlFor="fv-cid">Cidade</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="sc-uf"
										value={rest.fdUF}
										placeholder="Estado"
										onChange={(e) => onChangeAddress('fdUF', e.target.value)}
									/>
									<label htmlFor="sc-uf">Estado</label>
								</Form.Floating>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			<Row className="form-inner justify-content-sm-center">
				<Col xs={10}>
					<h3 className={`py-5 mb-0 text-red fw-bold ${(disbdCaractGerais) ? 'section-disabled' : ''}`}>{section1}</h3>
					<fieldset className={`caracteristicas-gerais ${(disbdCaractGerais) ? 'section-disabled' : ''}`} disabled={disbdCaractGerais}>
						<div className="mb-4">
							<h6 className="mb-0 py-2">1. Quantidade de funcionários da agência.</h6>
							<Form.Text>Apenas números</Form.Text>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="number"
										id="fv-qtde-funcs"
										name="qtde-funcs"
										value={rest.q1}
										placeholder="------"
										min={0}
										onChange={(e) => onChangeQuestion('q1', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">2. Quantidade de vigilantes no horário de expdiente na agência.</h6>
							<Form.Text>Apenas números</Form.Text>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="number"
										id="fv-qtde-vigis"
										name="qtde-vigis"
										value={rest.q2}
										placeholder="------"
										min={0}
										onChange={(e) => onChangeQuestion('q2', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">3. Em qual período do dia a procura pelos serviços é maior?</h6>
							<Form.Text>Selecione uma ou mais respostas</Form.Text>
							<Form.Check
								type="checkbox"
								id="fv-proc-maior-10a12"
								name="proc-maior-10a12"
								value="Entre às 10h e às 12h"
								label="Entre às 10h e às 12h"
								checked={rest.q3.includes("Entre às 10h e às 12h")}
								onChange={(e) => onChangeQuestion('q3', handle_checkbox_change(rest.q3, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-proc-maior-12a14"
								name="proc-maior-12a14"
								value="Entre às 12h e às 14h"
								label="Entre às 12h e às 14h"
								checked={rest.q3.includes("Entre às 12h e às 14h")}
								onChange={(e) => onChangeQuestion('q3', handle_checkbox_change(rest.q3, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-proc-maior-14a16"
								name="proc-maior-14a16"
								value="Entre às 14h e às 16h"
								label="Entre às 14h e às 16h"
								checked={rest.q3.includes("Entre às 14h e às 16h")}
								onChange={(e) => onChangeQuestion('q3', handle_checkbox_change(rest.q3, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-proc-maior-dia-todo"
								name="proc-maior-dia-todo"
								value="O dia todo"
								label="O dia todo"
								checked={rest.q3.includes("O dia todo")}
								onChange={(e) => onChangeQuestion('q3', handle_checkbox_change(rest.q3, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-proc-maior-nao-sei"
								name="proc-maior-nao-sei"
								value="Não sei responder"
								label="Não sei responder"
								checked={rest.q3.includes("Não sei responder")}
								onChange={(e) => onChangeQuestion('q3', handle_checkbox_change(rest.q3, e))}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">4. Filas de clientes são formadas na agência?</h6>
							<Row className="g-2">
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-filas-ag-sim"
										name="filas-ag"
										value="Sim"
										label="Sim"
										checked={rest.q4 === "Sim"}
										onChange={(e) => e.target.checked && onChangeQuestion('q4', e.target.value)}
									/>
								</Col>
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-filas-ag-nao"
										name="filas-ag"
										value="Não"
										label="Não"
										checked={rest.q4 === "Não"}
										onChange={(e) => e.target.checked && onChangeQuestion('q4', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">5. Qual o público majoritário da agência?</h6>
							<Form.Text>Selecione uma ou mais respostas</Form.Text>
							<Form.Check
								type="checkbox"
								id="fv-ag-pub-maj-jovens"
								name="ag-pub-maj-jovens"
								value="Jovens em início de carreira"
								label="Jovens em início de carreira"
								checked={rest.q5.includes("Jovens em início de carreira")}
								onChange={(e) => onChangeQuestion('q5', handle_checkbox_change(rest.q5, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-ag-pub-maj-ativ30mais"
								name="ag-pub-maj-ativ30mais"
								value="Adultos com mais de 30 anos e ativos financeiramente"
								label="Adultos com mais de 30 anos e ativos financeiramente"
								checked={rest.q5.includes("Adultos com mais de 30 anos e ativos financeiramente")}
								onChange={(e) => onChangeQuestion('q5', handle_checkbox_change(rest.q5, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-ag-pub-maj-idoso-nao-apos"
								name="ag-pub-maj-idoso-nao-apos"
								value="Idosos não aposentados"
								label="Idosos não aposentados"
								checked={rest.q5.includes("Idosos não aposentados")}
								onChange={(e) => onChangeQuestion('q5', handle_checkbox_change(rest.q5, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-ag-pub-maj-idoso-apos"
								name="ag-pub-maj-idoso-apos"
								value="Idosos aposentados"
								label="Idosos aposentados"
								checked={rest.q5.includes("Idosos aposentados")}
								onChange={(e) => onChangeQuestion('q5', handle_checkbox_change(rest.q5, e))}
							/>
						</div>
					</fieldset>
					<h3 className={`py-5 mb-0 text-red fw-bold ${(disbdServDisp) ? 'section-disabled' : ''}`}>{section2}</h3>
					<fieldset className={`servicos-disponiveis ${(disbdServDisp) ? 'section-disabled' : ''}`} disabled={disbdServDisp}>
						<div className="mb-4">
							<h6 className="mb-0 py-2">6. Qual(is) tipo(s) de produto possuem mais procura na agência?</h6>
							<Form.Check
								type="checkbox"
								id="fv-prod-abr-contas"
								name="prod-abr-contas"
								value="Abertura de Contas"
								label="Abertura de Contas"
								checked={rest.q6.includes("Abertura de Contas")}
								onChange={(e) => onChangeQuestion('q6', handle_checkbox_change(rest.q6, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-prod-ATM"
								name="prod-ATM"
								value="ATM"
								label="ATM"
								checked={rest.q6.includes("ATM")}
								onChange={(e) => onChangeQuestion('q6', handle_checkbox_change(rest.q6, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-prod-prospera"
								name="prod-prospera"
								value="Santander Prospera"
								label="Santander Prospera"
								checked={rest.q6.includes("Santander Prospera")}
								onChange={(e) => onChangeQuestion('q6', handle_checkbox_change(rest.q6, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-prod-getnet"
								name="prod-getnet"
								value="GetNet"
								label="GetNet"
								checked={rest.q6.includes("GetNet")}
								onChange={(e) => onChangeQuestion('q6', handle_checkbox_change(rest.q6, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-prod-invest"
								name="prod-invest"
								value="Investimentos"
								label="Investimentos"
								checked={rest.q6.includes("Investimentos")}
								onChange={(e) => onChangeQuestion('q6', handle_checkbox_change(rest.q6, e))}
							/>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="text"
										id="fv-prod-ofer-outros"
										name="prod-ofer-outro"
										value={extract_other_value(rest.q6)}
										placeholder="OUTRO"
										onChange={(e) => onChangeQuestion('q6', handle_other_change(rest.q6, e))}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">7. Quantidade de ATMs na agência..</h6>
							<Form.Text>Apenas números</Form.Text>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="number"
										id="fv-qtde-ATMs"
										name="qtde-ATMs"
										value={rest.q7}
										placeholder="------"
										min={0}
										onChange={(e) => onChangeQuestion('q7', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">8. Quantidade de guichês de caixa na agência.</h6>
							<Form.Text>Apenas números</Form.Text>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="number"
										id="fv-qtde-guiches"
										name="qtde-guiches"
										value={rest.q8}
										placeholder="------"
										min={0}
										onChange={(e) => onChangeQuestion('q8', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
					</fieldset>
					<h3 className={`py-5 mb-0 text-red fw-bold ${(disbdEstrLocaliz) ? 'section-disabled' : ''}`}>{section3}</h3>
					<fieldset className={`estrutura-localizacao ${(disbdEstrLocaliz) ? 'section-disabled' : ''}`} disabled={disbdEstrLocaliz}>
						<div className="mb-4">
							<h6 className="mb-0 py-2">9. A agência está localizada em uma:</h6>
							<Form.Check
								type="radio"
								id="fv-ag-localz-residencial"
								name="ag-localz"
								value="Área Residencial"
								label="Área Residencial"
								checked={rest.q9 === "Área Residencial"}
								onChange={(e) => e.target.checked && onChangeQuestion('q9', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="fv-ag-localz-comercial"
								name="ag-localz"
								value="Área Comercial"
								label="Área Comercial"
								checked={rest.q9 === "Área Comercial"}
								onChange={(e) => e.target.checked && onChangeQuestion('q9', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">10. A agência fica em uma área com fluxo intenso de pedestres?</h6>
							<Form.Check
								type="radio"
								id="fluxo-intenso-ped-sim"
								name="fluxo-intenso-ped"
								value="Sim"
								label="Sim"
								checked={rest.q10 === "Sim"}
								onChange={(e) => e.target.checked && onChangeQuestion('q10', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="fluxo-intenso-ped-nao"
								name="fluxo-intenso-ped"
								value="Não"
								label="Não"
								checked={rest.q10 === "Não"}
								onChange={(e) => e.target.checked && onChangeQuestion('q10', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">11. A agência fica em uma área com fluxo intenso de veículos?</h6>
							<Form.Check
								type="radio"
								id="fluxo-intenso-veic-sim"
								name="fluxo-intenso-veic"
								value="Sim"
								label="Sim"
								checked={rest.q11 === "Sim"}
								onChange={(e) => e.target.checked && onChangeQuestion('q11', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="fluxo-intenso-veic-nao"
								name="fluxo-intenso-veic"
								value="Não"
								label="Não"
								checked={rest.q11 === "Não"}
								onChange={(e) => e.target.checked && onChangeQuestion('q11', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">12. Qual o tamanho aproximado da fachada da agência, em metros?</h6>
							<Form.Text>Apenas números</Form.Text>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="number"
										id="fv-ag-tam-fachada"
										name="ag-tam-fachada"
										value={rest.q12}
										placeholder="------"
										min={0}
										onChange={(e) => onChangeQuestion('q12', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">13. Qual o tamanho aproximado do recuo da calçada da agência, em metros?</h6>
							<Form.Text>Apenas números</Form.Text>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="number"
										id="fv-ag-tam-calcada"
										name="ag-tam-calcada"
										value={rest.q13}
										placeholder="------"
										min={0}
										onChange={(e) => onChangeQuestion('q13', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">14. Quantidade de andares da agência.</h6>
							<Form.Text>Apenas números</Form.Text>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="number"
										id="fv-ag-qtde-andares"
										name="ag-qtde-andares"
										value={rest.q14}
										placeholder="------"
										min={0}
										onChange={(e) => onChangeQuestion('q14', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">15. A agência fica localizada em uma esquina?</h6>
							<Form.Check
								type="radio"
								id="ag-esquina-sim"
								name="ag-esquina"
								value="Sim"
								label="Sim"
								checked={rest.q15 === "Sim"}
								onChange={(e) => e.target.checked && onChangeQuestion('q15', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="ag-esquina-nao"
								name="ag-esquina"
								value="Não"
								label="Não"
								checked={rest.q15 === "Não"}
								onChange={(e) => e.target.checked && onChangeQuestion('q15', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">16. Existe algum obstáculo na entrada da agência?</h6>
							<Form.Text>Selecione uma ou mais respostas</Form.Text>
							<Form.Check
								type="checkbox"
								id="fv-ag-obst-entr-poste"
								name="ag-obst-entr-poste"
								value="Sim, poste de energia/telefonia"
								label="Sim, poste de energia/telefonia"
								checked={rest.q16.includes("Sim, poste de energia/telefonia")}
								onChange={(e) => onChangeQuestion('q16', handle_checkbox_change(rest.q16, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-ag-obst-entr-hidrante"
								name="ag-obst-entr-hidrante"
								value="Sim, hidrante"
								label="Sim, hidrante"
								checked={rest.q16.includes("Sim, hidrante")}
								onChange={(e) => onChangeQuestion('q16', handle_checkbox_change(rest.q16, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-ag-obst-entr-bueiro"
								name="ag-obst-entr-bueiro"
								value="Sim, bueiro"
								label="Sim, bueiro"
								checked={rest.q16.includes("Sim, bueiro")}
								onChange={(e) => onChangeQuestion('q16', handle_checkbox_change(rest.q16, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-ag-obst-entr-nao"
								name="ag-obst-entr-nao"
								value="Não"
								label="Não"
								checked={rest.q16.includes("Não")}
								onChange={(e) => onChangeQuestion('q16', handle_checkbox_change(rest.q16, e))}
							/>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="text"
										id="fv-ag-obst-entr-outro"
										name="ag-obst-entr-outro"
										value={extract_other_value(rest.q16)}
										placeholder="OUTRO"
										onChange={(e) => onChangeQuestion('q16', handle_other_change(rest.q16, e))}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">17. Existe algum estabelecimento de mobilidade em até 1 km da agência?</h6>
							<Form.Text>Selecione uma ou mais respostas</Form.Text>
							<Form.Check
								type="checkbox"
								id="fv-mob-pto-bus"
								name="mob-pto-bus"
								value="Ponto de ônibus"
								label="Ponto de ônibus"
								checked={rest.q17.includes("Ponto de ônibus")}
								onChange={(e) => onChangeQuestion('q17', handle_checkbox_change(rest.q17, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-mob-terminal-bus"
								name="mob-terminal-bus"
								value="Terminal de ônibus municipais"
								label="Terminal de ônibus municipais"
								checked={rest.q17.includes("Terminal de ônibus municipais")}
								onChange={(e) => onChangeQuestion('q17', handle_checkbox_change(rest.q17, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-mob-terminal-rodo"
								name="mob-terminal-rodo"
								value="Terminal rodoviário"
								label="Terminal rodoviário"
								checked={rest.q17.includes("Terminal rodoviário")}
								onChange={(e) => onChangeQuestion('q17', handle_checkbox_change(rest.q17, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-mob-pto-taxi"
								name="mob-pto-taxi"
								value="Ponto de táxi"
								label="Ponto de táxi"
								checked={rest.q17.includes("Ponto de táxi")}
								onChange={(e) => onChangeQuestion('q17', handle_checkbox_change(rest.q17, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-mob-aeroporto"
								name="mob-aeroporto"
								value="Aeroporto"
								label="Aeroporto"
								checked={rest.q17.includes("Aeroporto")}
								onChange={(e) => onChangeQuestion('q17', handle_checkbox_change(rest.q17, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-mob-estacao-metro"
								name="mob-estacao-metro"
								value="Estação de trem/metrô"
								label="Estação de trem/metrô"
								checked={rest.q17.includes("Estação de trem/metrô")}
								onChange={(e) => onChangeQuestion('q17', handle_checkbox_change(rest.q17, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-mob-nao"
								name="mob-nao"
								value="Não"
								label="Não"
								checked={rest.q17.includes("Não")}
								onChange={(e) => onChangeQuestion('q17', handle_checkbox_change(rest.q17, e))}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">18. Existe alguma instituição de ensino próxima à agência?</h6>
							<Form.Text>Selecione uma ou mais respostas</Form.Text>
							<Form.Check
								type="checkbox"
								id="fv-inst-ensino-fundamental"
								name="inst-ensino-fundamental"
								value="Sim, escola de ensino fundamental"
								label="Sim, escola de ensino fundamental"
								checked={rest.q18.includes("Sim, escola de ensino fundamental")}
								onChange={(e) => onChangeQuestion('q18', handle_checkbox_change(rest.q18, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-inst-ensino-medio"
								name="inst-ensino-medio"
								value="Sim, escola de ensino médio"
								label="Sim, escola de ensino médio"
								checked={rest.q18.includes("Sim, escola de ensino médio")}
								onChange={(e) => onChangeQuestion('q18', handle_checkbox_change(rest.q18, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-inst-ensino-fund-med"
								name="inst-ensino-fund-med"
								value="Sim, escola de ensino fundamental e médio"
								label="Sim, escola de ensino fundamental e médio"
								checked={rest.q18.includes("Sim, escola de ensino fundamental e médio")}
								onChange={(e) => onChangeQuestion('q18', handle_checkbox_change(rest.q18, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-inst-ensino-facul-pub"
								name="inst-ensino-facul-pub"
								value="Sim, faculdade pública"
								label="Sim, faculdade pública"
								checked={rest.q18.includes("Sim, faculdade pública")}
								onChange={(e) => onChangeQuestion('q18', handle_checkbox_change(rest.q18, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-inst-ensino-facul-part"
								name="inst-ensino-facul-part"
								value="Sim, faculdade particular"
								label="Sim, faculdade particular"
								checked={rest.q18.includes("Sim, faculdade particular")}
								onChange={(e) => onChangeQuestion('q18', handle_checkbox_change(rest.q18, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-inst-ensino-nao"
								name="inst-ensino-nao"
								value="Não"
								label="Não"
								checked={rest.q18.includes("Não")}
								onChange={(e) => onChangeQuestion('q18', handle_checkbox_change(rest.q18, e))}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">19. Qual a distância até o Shopping mais próximo?</h6>
							<Form.Check
								type="radio"
								id="shop-prox-agnoshop"
								name="shop-prox"
								value="A agência fica em um shopping"
								label="A agência fica em um shopping"
								checked={rest.q19 === "A agência fica em um shopping"}
								onChange={(e) => e.target.checked && onChangeQuestion('q19', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="shop-prox-ate1"
								name="shop-prox"
								value="Até 1 quadra"
								label="Até 1 quadra"
								checked={rest.q19 === "Até 1 quadra"}
								onChange={(e) => e.target.checked && onChangeQuestion('q19', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="shop-prox-1a3"
								name="shop-prox"
								value="De 1 a 3 quadras"
								label="De 1 a 3 quadras"
								checked={rest.q19 === "De 1 a 3 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q19', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="shop-prox-3a5"
								name="shop-prox"
								value="De 3 a 5 quadras"
								label="De 3 a 5 quadras"
								checked={rest.q19 === "De 3 a 5 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q19', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="shop-prox-5a10"
								name="shop-prox"
								value="De 5 a 10 quadras"
								label="De 5 a 10 quadras"
								checked={rest.q19 === "De 5 a 10 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q19', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="shop-prox-10mais"
								name="shop-prox"
								value="Mais de 10 quadras"
								label="Mais de 10 quadras"
								checked={rest.q19 === "Mais de 10 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q19', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">20. Qual a distância até o estacionamento privado mais próximo?</h6>
							<Form.Check
								type="radio"
								id="estac-priv-prox-agestac"
								name="estac-priv-prox"
								value="Existe um estacionamento privado na agência"
								label="Existe um estacionamento privado na agência"
								checked={rest.q20 === "Existe um estacionamento privado na agência"}
								onChange={(e) => e.target.checked && onChangeQuestion('q20', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="estac-priv-prox-menosde1"
								name="estac-priv-prox"
								value="Menos de 1km"
								label="Menos de 1km"
								checked={rest.q20 === "Menos de 1km"}
								onChange={(e) => e.target.checked && onChangeQuestion('q20', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="estac-priv-prox-maisde1"
								name="estac-priv-prox"
								value="Mais de 1 km"
								label="Mais de 1 km"
								checked={rest.q20 === "Mais de 1 km"}
								onChange={(e) => e.target.checked && onChangeQuestion('q20', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="estac-priv-prox-sem-estac"
								name="estac-priv-prox"
								value="Não tem estacionamento na região"
								label="Não tem estacionamento na região"
								checked={rest.q20 === "Não tem estacionamento na região"}
								onChange={(e) => e.target.checked && onChangeQuestion('q20', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">21. Existem restaurantes ou lanchonetes próximos à agência?</h6>
							<Form.Check
								type="radio"
								id="restlanch-prox-restnaag"
								name="restlanch-prox"
								value="Sim, existe um restaurante/lanchonete dentro da agência"
								label="Sim, existe um restaurante/lanchonete dentro da agência"
								checked={rest.q21 === "Sim, existe um restaurante/lanchonete dentro da agência"}
								onChange={(e) => e.target.checked && onChangeQuestion('q21', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="restlanch-prox-ate1"
								name="restlanch-prox"
								value="Sim, em menos de 1 quadra"
								label="Sim, em menos de 1 quadra"
								checked={rest.q21 === "Sim, em menos de 1 quadra"}
								onChange={(e) => e.target.checked && onChangeQuestion('q21', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="restlanch-prox-1a3"
								name="restlanch-prox"
								value="Sim, entre 1 e 3 quadras"
								label="Sim, entre 1 e 3 quadras"
								checked={rest.q21 === "Sim, entre 1 e 3 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q21', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="restlanch-prox-3a5"
								name="restlanch-prox"
								value="Sim, entre 3 e 5 quadras"
								label="Sim, entre 3 e 5 quadras"
								checked={rest.q21 === "Sim, entre 3 e 5 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q21', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="restlanch-prox-5amis"
								name="restlanch-prox"
								value="Não, mais de 5 quadras"
								label="Não, mais de 5 quadras"
								checked={rest.q21 === "Não, mais de 5 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q21', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="restlanch-prox-nao"
								name="restlanch-prox"
								value="Não existem estabelecimentos alimentícios na região"
								label="Não existem estabelecimentos alimentícios na região"
								checked={rest.q21 === "Não existem estabelecimentos alimentícios na região"}
								onChange={(e) => e.target.checked && onChangeQuestion('q21', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">22. Qual a distância até a instituição de ensino mais próxima?</h6>
							<Form.Check
								type="radio"
								id="ensino-prox-agnainstensino"
								name="ensino-prox"
								value="A agência fica em uma instituição de ensino"
								label="A agência fica em uma instituição de ensino"
								checked={rest.q22 === "A agência fica em uma instituição de ensino"}
								onChange={(e) => e.target.checked && onChangeQuestion('q22', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="ensino-prox-ate1"
								name="ensino-prox"
								value="Até 1 quadra"
								label="Até 1 quadra"
								checked={rest.q22 === "Até 1 quadra"}
								onChange={(e) => e.target.checked && onChangeQuestion('q22', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="ensino-prox-1a3"
								name="ensino-prox"
								value="De 1 a 3 quadras"
								label="De 1 a 3 quadras"
								checked={rest.q22 === "De 1 a 3 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q22', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="ensino-prox-3a5"
								name="ensino-prox"
								value="De 3 a 5 quadras"
								label="De 3 a 5 quadras"
								checked={rest.q22 === "De 3 a 5 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q22', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="ensino-prox-5a10"
								name="ensino-prox"
								value="De 5 a 10 quadras"
								label="De 5 a 10 quadras"
								checked={rest.q22 === "De 5 a 10 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q22', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="ensino-prox-10mais"
								name="ensino-prox"
								value="Mais de 10 quadras"
								label="Mais de 10 quadras"
								checked={rest.q22 === "Mais de 10 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q22', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">23. Existe algum estabelecimento que oferece empréstimos (exceto agências bancárias) próximo à agência?</h6>
							<Form.Check
								type="radio"
								id="emprest-banc-prox-ate1"
								name="emprest-banc-prox"
								value="Sim, em menos de 1 quadra"
								label="Sim, em menos de 1 quadra"
								checked={rest.q23 === "Sim, em menos de 1 quadra"}
								onChange={(e) => e.target.checked && onChangeQuestion('q23', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="emprest-banc-prox-1a3"
								name="emprest-banc-prox"
								value="Sim, entre 1 e 3 quadras"
								label="Sim, entre 1 e 3 quadras"
								checked={rest.q23 === "Sim, entre 1 e 3 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q23', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="emprest-banc-prox-3a5"
								name="emprest-banc-prox"
								value="Sim, entre 3 e 5 quadras"
								label="Sim, entre 3 e 5 quadras"
								checked={rest.q23 === "Sim, entre 3 e 5 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q23', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="emprest-banc-prox-5amis"
								name="emprest-banc-prox"
								value="Não, mais de 5 quadras"
								label="Não, mais de 5 quadras"
								checked={rest.q23 === "Não, mais de 5 quadras"}
								onChange={(e) => e.target.checked && onChangeQuestion('q23', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">24. A agência fica localizada dentro de outro estabelecimento?</h6>
							<Form.Check
								type="radio"
								id="ag-no-estab-facul"
								name="ag-no-estab"
								value="Faculdade"
								label="Faculdade"
								checked={rest.q24 === "Faculdade"}
								onChange={(e) => e.target.checked && onChangeQuestion('q24', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="ag-no-estab-shop"
								name="ag-no-estab"
								value="Shopping"
								label="Shopping"
								checked={rest.q24 === "Shopping"}
								onChange={(e) => e.target.checked && onChangeQuestion('q24', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="ag-no-estab-empresarial"
								name="ag-no-estab"
								value="Prédio empresarial"
								label="Prédio empresarial"
								checked={rest.q24 === "Prédio empresarial"}
								onChange={(e) => e.target.checked && onChangeQuestion('q24', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="ag-no-estab-publico"
								name="ag-no-estab"
								value="Prédio de órgão público"
								label="Prédio de órgão público"
								checked={rest.q24 === "Prédio de órgão público"}
								onChange={(e) => e.target.checked && onChangeQuestion('q24', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="ag-no-estab-nao"
								name="ag-no-estab"
								value="Não"
								label="Não"
								checked={rest.q24 === "Não"}
								onChange={(e) => e.target.checked && onChangeQuestion('q24', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">25. Existe alguma agência bancária em até 1km da agência?</h6>
							<Row className="g-2">
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-outr-ag-perto-sim"
										name="outr-ag-perto"
										value="Sim"
										label="Sim"
										checked={rest.q25 === "Sim"}
										onChange={(e) => e.target.checked && onChangeQuestion('q25', e.target.value)}
									/>
								</Col>
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-outr-ag-perto-nao"
										name="outr-ag-perto"
										value="Não"
										label="Não"
										checked={rest.q25 === "Não"}
										onChange={(e) => e.target.checked && onChangeQuestion('q25', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">26. De qual banco é a agência bancária mais próxima?</h6>
							<Form.Text>Selecione uma ou mais respostas</Form.Text>
							<Form.Check
								type="checkbox"
								id="fv-banco-ag-prox-bradesco"
								name="banco-ag-prox-bradesco"
								value="Bradesco"
								label="Bradesco"
								checked={rest.q26.includes("Bradesco")}
								onChange={(e) => onChangeQuestion('q26', handle_checkbox_change(rest.q26, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-banco-ag-prox-itau"
								name="banco-ag-prox-itau"
								value="Itaú"
								label="Itaú"
								checked={rest.q26.includes("Itaú")}
								onChange={(e) => onChangeQuestion('q26', handle_checkbox_change(rest.q26, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-banco-ag-prox-bb"
								name="banco-ag-prox-bb"
								value="Banco do Brasil"
								label="Banco do Brasil"
								checked={rest.q26.includes("Banco do Brasil")}
								onChange={(e) => onChangeQuestion('q26', handle_checkbox_change(rest.q26, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-banco-ag-prox-safra"
								name="banco-ag-prox-safra"
								value="Safra"
								label="Safra"
								checked={rest.q26.includes("Safra")}
								onChange={(e) => onChangeQuestion('q26', handle_checkbox_change(rest.q26, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-banco-ag-prox-caixa"
								name="banco-ag-prox-caixa"
								value="Caixa"
								label="Caixa"
								checked={rest.q26.includes("Caixa")}
								onChange={(e) => onChangeQuestion('q26', handle_checkbox_change(rest.q26, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-banco-ag-prox-nao"
								name="banco-ag-prox-nao"
								value="Não há agências de outros bancos nas proximidades"
								label="Não há agências de outros bancos nas proximidades"
								checked={rest.q26.includes("Não há agências de outros bancos nas proximidades")}
								onChange={(e) => onChangeQuestion('q26', handle_checkbox_change(rest.q26, e))}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">27. A agência fica próxima a um ponto turístico?</h6>
							<Form.Check
								type="radio"
								id="pto-tur-prox-ate1"
								name="pto-tur-prox"
								value="Sim, a menos de 1 quilômetro"
								label="Sim, a menos de 1 quilômetro"
								checked={rest.q27 === "Sim, a menos de 1 quilômetro"}
								onChange={(e) => e.target.checked && onChangeQuestion('q27', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="pto-tur-prox-1a3"
								name="pto-tur-prox"
								value="Sim, entre 1 e 3 quilômetros"
								label="Sim, entre 1 e 3 quilômetros"
								checked={rest.q27 === "Sim, entre 1 e 3 quilômetros"}
								onChange={(e) => e.target.checked && onChangeQuestion('q27', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="pto-tur-prox-3a5"
								name="pto-tur-prox"
								value="Sim, entre 3 e 5 quilômetros"
								label="Sim, entre 3 e 5 quilômetros"
								checked={rest.q27 === "Sim, entre 3 e 5 quilômetros"}
								onChange={(e) => e.target.checked && onChangeQuestion('q27', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="pto-tur-prox-5amis"
								name="pto-tur-prox"
								value="Não, mais de 5 quilômetros"
								label="Não, mais de 5 quilômetros"
								checked={rest.q27 === "Não, mais de 5 quilômetros"}
								onChange={(e) => e.target.checked && onChangeQuestion('q27', e.target.value)}
							/>
						</div>
					</fieldset>
				</Col>
			</Row>
		</Container>
	);
};

function handle_checkbox_change(arr, e){
	let [...arrnew] = arr;
	if(e.target.checked){
		arrnew[arrnew.length] = e.target.value; // add value at end
	}
	else{
		arrnew.splice(arrnew.indexOf(e.target.value), 1);
	}

	return arrnew;
}

function extract_other_value(q){
	let foundv = q.find((v) => v.includes('[outro]'));

	return (foundv) ? foundv.replace('[outro]', '') : '';
}

function handle_other_change(arr, e){
	let [...arrnew] = arr;
	let i = arrnew.findIndex((v) => v.includes('[outro]'));
	i = (i !== -1) ? i : arr.length;
	if(e.target.value){
		arrnew[i] = `[outro]${e.target.value}`;
	}
	else{
		arrnew.splice(i, 1);
	}

	return arrnew;
}

function export_to_json_file(jsonData){
	let dataStr = JSON.stringify(jsonData);
	let dataUri = 'data:application/json;charset=utf-8,'+encodeURIComponent(dataStr);
	let exportFileDefaultName = 'simulacao.json';
	let a = document.createElement('a');
	a.setAttribute('href', dataUri);
	a.setAttribute('download', exportFileDefaultName);
	a.click();
}

function export_to_csv_file(jsonData){
	let dataStr = json_to_formated_csv(jsonData);
	let dataUri = 'data:text/csv;charset=utf-8,'+encodeURIComponent(dataStr);
	let exportFileDefaultName = 'simulacao.csv';
	let a = document.createElement('a');
	a.setAttribute('href', dataUri);
	a.setAttribute('download', exportFileDefaultName);
	a.click();
}

function json_to_formated_csv(json){
	var linhas = [];
	var num_answer_cols = 2; // initially 2, because of lat/lng have 2 answer cols
	// Multivalue fields can extend the number of answer columns, so we redefine this number
	for(var k in json){
		if(Array.isArray(json[k]) && (json[k].length > num_answer_cols)){
			num_answer_cols = json[k].length;
		}
	}
	var total_cols = num_answer_cols + 1;
	for(var k in json){
		var a = [];
		if(k === 'markers'){
			a = ['lat/lng', `lat:${json[k][0]["lat"]}`, `lng:${json[k][0]["lng"]}`];
		}
		else if(json[k].constructor === Array){
			a = [k, ...json[k]];
		}
		else{
			a = [k.replace('fd', ''), json[k]];
		}
		// Fill extra cols until total
		while(a.length < total_cols){
			a.push('');
		}
		linhas[linhas.length] = a.join(';'); // tranform the line in string separated by ";"
	}

	return linhas.join('\n');
}

export default FormView;
